import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { Avatar, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import FlatButton from 'shared-components/buttons/FlatButton'
import DividerOr from 'components/common/DividerOr'

import { Apple, ChevronRight } from '@mui/icons-material'

import dynamic from 'next/dynamic'
import mixpanel from 'shared-components/utils/mixpanel'
import { useRouter } from 'next/router'
import { validate } from 'email-validator'
import TextInput from 'shared-components/common/TextInput'
import apiClient from '../../shared-components/utils/ApiClient'
import GoogleSignInButton from './GoogleSignInButton'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useContainerDimensions } from 'utils/hooks'
import EnterCodeview from './EnterCodeView'
import ProfileAvatar from '../../../pages/profile/avatar'
import { closeSignInModal } from '../../actions/modals'
import { fetchEvent, rsvpFlow } from '../../actions/event'
import { getCurrentEvent } from '../../selectors/event'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../shared-components/buttons/BashButton'
import Profile from '../../../pages/profile'
import { follow, setUser } from '../../actions/user'
import { rsvpHype } from '../../actions/rsvp'

const AppleSignin = dynamic(() => import('react-apple-signin-auth'), {
  ssr: false,
})

const useContinueButtonsStyles = makeStyles()((theme) => ({
  buttonsContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  appleButton: {
    backgroundColor: 'white',
    color: '#3c4043',
    border: '1px solid #dadce0',
    marginTop: theme.spacing(1.5),
    // fontFamily: 'Google Sans, arial, sans-serif',
    fontWeight: 500,
    paddingLeft: '12px',
    height: 40,
    '&:hover': {
      backgroundColor: '#f6f6f6',
    },
    // width: 'fit-content',
  },
  whiteButton: {
    backgroundColor: 'white',
    color: 'black',
    marginBottom: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: theme.spacing(2),
  },
  googleButton: {
    backgroundColor: theme.palette.grey[300],
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
    },
  },
  divider: {
    background: '1px solid ' + theme.palette.grey.main,
    margin: theme.spacing(0, -3),
  },
  error: {
    marginBottom: theme.spacing(2),
    color: theme.palette.red.main,
  },
  input: {
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 8,
    // height: 40
  },
  email: {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  socialButtons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  policyTerms: {
    marginTop: theme.spacing(2),
    color: '#A6A6A6',
  },
  link: {
    // color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  emailButton: {
    width: '100%',
  },
  caption: {
    marginTop: theme.spacing(2),
  },
  enterCode: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  required: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2, 1.5, 2, 1.5),
    borderRadius: 8,
    backgroundColor: '#FFF2F0',
    display: 'flex',
    gap: theme.spacing(1.5),
    color: '#4D4D4D',
  },
  avatar: {
    width: 32,
    height: 32,
  },
  profileContainer: {
    paddingTop: theme.spacing(1),
    overflowY: 'auto',
  },
  profileAvatarContainer: {
    paddingTop: theme.spacing(4),
  },
}))

const SignInButtons = ({
  organisation,
  useWhiteButtons,
  emailToSend,
  showTitle = true,
  showProfile,
  showAvatar,
  setShowProfile,
  setShowAvatar,
}) => {
  const { classes } = useContinueButtonsStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()
  const [submittingApple, setSubmittingApple] = useState(false)
  const [loading, setLoading] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [email, setEmail] = useState(emailToSend ?? '')
  const user = useSelector((state) => state.user.user)
  const event = useSelector(getCurrentEvent)
  const rsvpAfterSignIn = useSelector((state) => state.modals.rsvpAfterSignIn)
  const hypeAfterSignIn = useSelector((state) => state.modals.hypeAfterSignIn)
  const stripeCustomerId = useSelector((state) => state.modals.stripeCustomerId)
  const newGuest = useSelector((state) => state.rsvp.newGuest)
  const organisationToFollow = useSelector(
    (state) => state.modals.organisationToFollow,
  )

  const [googleDisabled, setGoogleDisabled] = useState(false)

  const onDoneLoadingUser = (result) => {
    dispatch(setUser(result))
    if (organisationToFollow) {
      dispatch(follow())
    }
    if (!result.firstName || !result.hasAvatar) {
      setShowProfile(true)
    } else if (rsvpAfterSignIn) {
      dispatch(closeSignInModal())
      dispatch(rsvpFlow(newGuest.status, 'web_event_page', stripeCustomerId))
    } else if (hypeAfterSignIn) {
      dispatch(closeSignInModal())
      dispatch(rsvpHype('hype_button_web', null, true))
    } else {
      dispatch(closeSignInModal())
    }
  }

  const signIn3rdParty = async (
    token,
    type,
    timeZone,
    organisationToFollow,
  ) => {
    let followProfileId
    if (organisationToFollow) followProfileId = organisationToFollow.id
    try {
      const user = await apiClient.user.verifyToken3rdParty(
        token,
        type,
        timeZone,
        'web',
        followProfileId,
      )
      console.log('user 3rd party', user)
      mixpanel.track('Sign In Successful')
      onDoneLoadingUser(user)
    } catch (e) {
      console.warn('Google token submit not succeeded', e)
    }
  }

  const handleAppleResponse = (response) => {
    setSubmittingApple(true)
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    signIn3rdParty(
      response.authorization.id_token,
      'Apple',
      timeZone,
      organisation,
      router,
    )
  }

  useEffect(() => {
    if (emailToSend) {
      setHasSubmitted(true)
      onSubmit()
    }
  }, [])

  const handleFailure = (response) => {
    if (response.error === 'idpiframe_initialization_failed') {
      setGoogleDisabled(true)
    }
  }

  const onSubmit = useCallback(async () => {
    if (validate(email)) {
      setLoading(true)
      mixpanel.identify(mixpanel.get_distinct_id())
      mixpanel.people.set('$email', email)
      mixpanel.track('Sign In', {
        'Sign In Type': 'email',
        'Email Domain': email.split('@')[1],
      })
      await apiClient.user.requestCode(
        email,
        window.location.pathname === '/' ||
          window.location.pathname === '/signIn'
          ? null
          : window.location.href,
        null,
        rsvpAfterSignIn,
      )
      setLoading(false)
      setHasSubmitted(true)
    } else {
      setInvalidEmail(true)
    }
  }, [email])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  const ref = useRef(null)
  const { width, height } = useContainerDimensions(ref)

  const onEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const terms = (
    <Typography
      variant='caption'
      color='textSecondary'
      className={classes.policyTerms}
    >
      <Trans t={t} i18nKey='privacyPolicyAndTerms'>
        Bash has a
        <a
          className={classes.link}
          href='/privacy'
          target='_blank'
          rel='noreferrer'
        >
          Privacy Policy
        </a>
        and
        <a
          className={classes.link}
          href='/terms'
          target='_blank'
          rel='noreferrer'
        >
          Terms and Conditions
        </a>
        . By signing in you agree with the terms.
      </Trans>
    </Typography>
  )

  if (showAvatar) {
    return (
      <div className={classes.profileAvatarContainer}>
        <ProfileAvatar
          onDone={() => {
            if (rsvpAfterSignIn) {
              dispatch(closeSignInModal())
              dispatch(
                rsvpFlow(newGuest.status, 'web_event_page', stripeCustomerId),
              )
            } else if (hypeAfterSignIn) {
              dispatch(closeSignInModal())
              dispatch(rsvpHype('hype_button_web', null, true))
            } else {
              dispatch(closeSignInModal())
              dispatch(fetchEvent(event.code))
            }
          }}
        />
      </div>
    )
  }

  if (showProfile) {
    return (
      <div className={classes.profileContainer}>
        <Profile
          onDone={() => {
            setShowAvatar(true)
          }}
        />
      </div>
    )
  }

  if (hasSubmitted) {
    return (
      <>
        <EnterCodeview
          email={email}
          setHasSubmitted={setHasSubmitted}
          onDoneLoadingUser={onDoneLoadingUser}
        />
        {terms}
      </>
    )
  }

  const hostName = event.hosts?.[0]?.model?.name ?? t('thisOrganiser')

  return (
    <>
      {showTitle && (
        <Typography variant='h6' className={classes.title}>
          {t('signInToContinue')}
        </Typography>
      )}
      {router.query.redirectTo === '/public/create' && (
        <Typography variant='caption' className={classes.subtitle}>
          {t('loginWithPersonal')}
        </Typography>
      )}
      {rsvpAfterSignIn && (
        <div className={classes.required}>
          <Avatar
            src={event.hosts?.[0]?.model?.avatarUrls?.lg}
            className={classes.avatar}
          />
          <Typography variant='body1'>
            <span style={{ fontWeight: 500 }}>{hostName}</span>{' '}
            {t('requestsThatYouVerifyYourEmail')}
          </Typography>
        </div>
      )}
      <div className={classes.buttonsContainer}>
        {/*<Typography className={classes.email} variant='body1'>*/}
        {/*  {t('Email')}*/}
        {/*</Typography>*/}
        <TextInput
          type='email'
          onKeyDown={handleKeyDown}
          customClass={classes.input}
          // label={t('Email')}
          placeholder={t('emailExample')}
          value={email}
          onChange={onEmailChange}
          style={{ height: 40 }}
          autoFocus={true}
        />
        {invalidEmail && (
          <Typography className={classes.error} variant='caption'>
            {t('invalidEmail')}
          </Typography>
        )}

        <BashButton
          type={BashButtonType.PRIMARY}
          onClick={onSubmit}
          extraView={<ChevronRight />}
          viewAlignment={ButtonViewAlignment.ALIGN_VIEW_END}
          className={classes.emailButton}
          loading={loading}
        >
          {t('signInButtons.email')}
        </BashButton>

        {!useWhiteButtons && <DividerOr />}
        <div className={classes.socialButtons} ref={ref}>
          {!googleDisabled && (
            <GoogleOAuthProvider clientId='477034137380-75omcujajv63gpnlmggg74ti1bqjrpsh.apps.googleusercontent.com'>
              <GoogleSignInButton
                organisation={organisation}
                width={width}
                signIn3rdParty={signIn3rdParty}
              />
            </GoogleOAuthProvider>
          )}

          <AppleSignin
            fullWidth
            authOptions={{
              clientId: 'social.bash.app',
              scope: 'email name',
              redirectURI: process.env.NEXT_PUBLIC_WEBSITE,
              usePopup: true,
            }}
            uiType='dark'
            onSuccess={handleAppleResponse}
            onError={handleFailure}
            iconProp={{ style: { marginTop: '10px' } }}
            render={(renderProps) => (
              <FlatButton
                id='appleButton'
                color='inherit'
                fullWidth
                elevation={useWhiteButtons ? 2 : undefined}
                endIcon={<div />}
                disableElevation={!useWhiteButtons}
                className={
                  useWhiteButtons ? classes.whiteButton : classes.appleButton
                }
                startIcon={<Apple style={{ marginLeft: 2 }} color='inherit' />}
                onClick={(e) => {
                  mixpanel.track('Sign In', { 'Sign In Type': 'apple' })
                  renderProps.onClick(e)
                }}
                disabled={renderProps.disabled}
                loading={submittingApple}
              >
                {t('signInButtons.apple')}
              </FlatButton>
            )}
          />
        </div>
        {terms}
      </div>
    </>
  )
}

export default SignInButtons
