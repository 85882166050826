import React, { useEffect, useState } from 'react'

import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import TextInput from '../../src/shared-components/common/TextInput'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser, patchUser } from '../../src/actions/user'
import LargeCentralPaper from '../../src/components/common/LargeCentralPaper'
import { useRouter } from 'next/router'
import Button from '@mui/material/Button'
import mixpanel from 'shared-components/utils/mixpanel'
import { getActiveGuestWithoutNew } from '../../src/selectors/event'
import BashButton, {
  BashButtonType,
} from '../../src/shared-components/buttons/BashButton'

const useProfileStyles = makeStyles()((theme) => ({
  paper: {
    overflow: 'visible',
  },
  marginTopHalf: {
    marginTop: theme.spacing(0.5),
  },
  doubleInput: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(2),
    marginTop: theme.spacing(1),
    width: '100%',
  },
  saveButton: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  signOutButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  signOutButtonFs: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  subtext: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1.5),
  },
}))

const Profile = ({ onDone }) => {
  const { classes } = useProfileStyles()
  const { t } = useTranslation('common')
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()
  const router = useRouter()
  const [hasMounted, setHasMounted] = React.useState(false)
  const activeGuest = useSelector(getActiveGuestWithoutNew)
  console.log('activeGuest', activeGuest)
  const [firstName, setFirstName] = useState(
    activeGuest?.name.split(' ')[0] ?? '',
  )
  const [lastName, setLastName] = useState(
    activeGuest?.name?.split(' ').slice(1).join(' '),
  )
  const [saving, setSaving] = useState(false)
  const saveEnabled = !!firstName && !!lastName
  const { redirectTo } = router.query

  useEffect(() => {
    console.log('mounting profile')
    setHasMounted(true)
  }, [])

  useEffect(() => {
    const name = window.localStorage && window.localStorage.getItem('name')
    name && setFirstName(name.split(' ')[0])
    name && window.localStorage && window.localStorage.removeItem('name')
  }, [])

  const onConfirm = async () => {
    setSaving(true)
    mixpanel.track('Add Name', { $name: firstName })
    mixpanel.track('Add Last Name', { 'Location in App': 'activation' })
    mixpanel.people.set('$name', firstName)
    mixpanel.track('Confirm Activation')
    await dispatch(
      patchUser({
        name: lastName ? firstName + ' ' + lastName : firstName,
        firstName,
        lastName,
      }),
    )
    onDone
      ? onDone()
      : redirectTo
        ? router.push(`/profile/avatar?redirectTo=${redirectTo}`)
        : router.push('/profile/avatar')
  }

  const middle = (
    <>
      {/* <div className={classes.doubleInput}> */}
      <TextInput
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        label={t('firstName')}
        placeholder={t('john')}
      />
      <TextInput
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        label={t('lastName')}
        placeholder={t('doe')}
      />
      {/* </div> */}

      <BashButton
        type={BashButtonType.PRIMARY}
        onClick={onConfirm}
        loading={saving}
        enabled={saveEnabled}
        className={classes.saveButton}
      >
        {t('continue')}
      </BashButton>

      {/*<FlatButton*/}
      {/*  onClick={onConfirm}*/}
      {/*  loading={saving}*/}
      {/*  disabled={!saveEnabled}*/}
      {/*  className={classes.saveButton}*/}
      {/*  color='primary'*/}
      {/*>*/}
      {/*  {t('continue')}*/}
      {/*</FlatButton>*/}
    </>
  )

  if (onDone) {
    return middle
  }

  if (!hasMounted) return null
  return (
    <div className={classes.root}>
      {fullScreen && (
        <Button
          onClick={() => {
            dispatch(logoutUser())
            router.push('/home')
          }}
          className={classes.signOutButton}
        >
          {t('profileSettings.signOut')}
        </Button>
      )}
      <LargeCentralPaper className={classes.paper}>
        {!fullScreen && (
          <Button
            onClick={() => dispatch(logoutUser())}
            className={classes.signOutButtonFs}
          >
            {t('profileSettings.signOut')}
          </Button>
        )}
        <Typography variant='h6'>{t('yourProfile')}</Typography>
        <Typography
          variant='subtitle2'
          color='textSecondary'
          className={classes.marginTopHalf}
        >
          {t('newProfileSubtitle')}
        </Typography>
        {middle}
        <Typography className={classes.subtext} variant='caption'>
          {t('pagesCanBeMadeLater')}
        </Typography>
      </LargeCentralPaper>
    </div>
  )
}
export default Profile
